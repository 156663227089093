import React from 'react';
import Img from '../../utils/OptimizedImage';
import shareSocialLink from '../../utils/shareSocialLink';
import facebook from '../../images/blog_share_facebook.svg';
import twitter from '../../images/blog_share_twitter.svg';
import linkedin from '../../images/blog_share_linkedin.svg';

const ShareLinks = ({ slug, title }) => {
  return (
    <div className="social-share">
      <span className="no-pointer">Share</span>
      <span onClick={() => shareSocialLink('facebook', `/blog/${slug}`)}>
        <Img src={facebook} alt="facebook icon" />
      </span>
      <span onClick={() => shareSocialLink('twitter', `/blog/${slug}`)}>
        <Img src={twitter} alt="twitter icon" />
      </span>
      <span onClick={() => shareSocialLink('linkedin', `/blog/${slug}`, title)}>
        <Img src={linkedin} alt="linkedin icon" />
      </span>
    </div>
  );
};

export default ShareLinks;
